import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "cancel-a-task"
    }}>{`Cancel a Task`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`After a tasking request has reached an `}<inlineCode parentName="p">{`accepted`}</inlineCode>{` or `}<inlineCode parentName="p">{`active`}</inlineCode>{` status, sometimes it is necessary to cancel the request before it is scheduled to be collected. When canceling a task, the system will update the task status to `}<inlineCode parentName="p">{`canceled`}</inlineCode>{` and remove the task from the active schedule. This is a terminal state and cannot be reversed by the API user. The API user is subject to any charges per the `}<a parentName="p" {...{
        "href": "https://support.capellaspace.com/hc/en-us/articles/360059276331-What-is-the-tasking-cancellation-policy-"
      }}>{`Capella Cancellation Policy`}</a>{`.`}</p>
    <h2 {...{
      "id": "canceling-a-task"
    }}>{`Canceling a Task`}</h2>
    <h3 {...{
      "id": "single-tasking-request"
    }}>{`Single Tasking Request`}</h3>
    <p>{`To cancel a single tasking request, you will send a request to the status endpoint with `}<inlineCode parentName="p">{`status`}</inlineCode>{` set to `}<inlineCode parentName="p">{`cancelled`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`PATCH https://api.capellaspace.com/task/{{taskingrequestId}}/status

{
    "status":"canceled"
}

NOTE: a value of either 'canceled' or 'cancelled' is accepted and will be returned as 'canceled'
`}</code></pre>
    <h3 {...{
      "id": "repeat-tasking-request"
    }}>{`Repeat Tasking Request`}</h3>
    <p>{`To cancel a repeat tasking request, you will send a request to the status endpoint with `}<inlineCode parentName="p">{`code`}</inlineCode>{` set to `}<inlineCode parentName="p">{`canceled`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`PATCH https://api.capellaspace.com/repeat-requests/{{repeatrequestId}}/status

{
    "code":"canceled"
}

NOTE: a value of either 'canceled' or 'cancelled' is accepted and will be returned as 'canceled'
`}</code></pre>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on tasking cancellation, see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/tasking"
      }}>{`Tasking`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      